import { configureStore, createSlice } from "@reduxjs/toolkit";

let user = createSlice({
  name: "userInfo",
  initialState: "",
  reducers: {
    userChange(state, a) {
      return a.payload;
    },
  },
});

let playing = createSlice({
  name: "playing",
  initialState: false,
  reducers: {
    playChange(state, a) {
      return a.payload;
    },
  },
});
let playList = createSlice({
  name: "playList",
  initialState: [
    {
      id: 0,
      music: "KBS 1Radio",
      img: "KBS1Radio.png",
      radio:
        //"https://1radio.gscdn.kbs.co.kr/1radio_192_4.m3u8?Expires=1682641133&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly8xcmFkaW8uZ3NjZG4ua2JzLmNvLmtyLzFyYWRpb18xOTJfNC5tM3U4IiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNjgyNjQxMTMzfX19XX0_&Signature=LV8jRsdZSCdaB2n-lzdtL5SzSGCJfOX1U5nc1BIrwXMQfRqFKM792JXLNeGdjXVBaQaQY-rNSBQeqEXFMmpPmj0vrJg2oCm59Tz8L9~7igSJ8hliTyerSECzgV3hRmXvH~6KKP9oTwO59AE970apXMtYt0N7mEEiVe1d~6rgBpoGMvi23lSvQmeOAmESbB1vQUgHhofUGQ50n02zxxfER14pQ5lcbVz-5s0b22Ajnt6k87sZw6ZE8XKJRRI9gguX58bcf~RFs4ZWhKZrrAkoevpumsvGuos5l6t9HZ820f~wdpBiqbzmkcR~9rJcGufbLGzfj6iB0kbh3eQ-jwo9Tw__&Key-Pair-Id=APKAICDSGT3Y7IXGJ3TA",
        "https://1radio.gscdn.kbs.co.kr/1radio_192_4.m3u8?Expires=1686398404&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly8xcmFkaW8uZ3NjZG4ua2JzLmNvLmtyLzFyYWRpb18xOTJfNC5tM3U4IiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNjg2Mzk4NDA0fX19XX0_&Signature=SY928W0UmlqoC2RvXYDx6~W1jPysFy-LV~zfcnPWlaMxiAgSUKoSRZHPiebUJ~P9o8JCzinIxe-s-dpfA1rGv3uyibsbRBLkgXMg7KaVRdSkPa5oWT1HkpLEfKyY446oHHm9XUN6Ayit252ej8uux23rDJX0NGEQ4ka4Nd4QwbLU6rvP9knFuEjWmcsbqAf-P4DlQ48nO~2ndX6btR8ChEKEjAegUo~Loqypfh9Zp~SCcHCdxqZ-oJS42lU364D0rt5Mb~iGb-WyARh4tnIRpjVPkG1Gnnu~yPacslGzYHlrk~zIBC-QKm1L3bnOVFdQR866f20mRmlljegQzDFWrw__&Key-Pair-Id=APKAICDSGT3Y7IXGJ3TA",
    },
    {
      id: 1,
      music: "KBS FM",
      img: "KBSFM.png",
      radio:
        //"https://1fm.gscdn.kbs.co.kr/1fm_192_2.m3u8?Expires=1682643573&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly8xZm0uZ3NjZG4ua2JzLmNvLmtyLzFmbV8xOTJfMi5tM3U4IiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNjgyNjQzNTczfX19XX0_&Signature=OXDTdVu2SYpHqw34ptycWJsxb-Z~Wgm0b~fZw6~NZLyOU7JnM9tYz-oB8-v0SmslQzryBBea32Wv8muQDuO2KYEAZbw46FB9JzV2c~iir~2JmWjduHhc4l8vzUGFkJone6peBI514grNrO~cf~LE9g1naI7CCa3OzkbgugflG7tzizW8b2ZcL7nG2QtIfymodXC4XkO016xVL5aNoqxJxTU26a9gqbJPW6EOD1zJEJLpjzRKCcibK8FyztbQPRjKdaFXjDMiEKzwY7g-aBOUyh48ZgIuragF4uX9Kwn7XGa15PsdL0LoYGAkUAWtF0fspejHT5GxeaNhE2RzKNsShA__&Key-Pair-Id=APKAICDSGT3Y7IXGJ3TA",
        "https://1fm.gscdn.kbs.co.kr/1fm_192_2.m3u8?Expires=1686398362&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly8xZm0uZ3NjZG4ua2JzLmNvLmtyLzFmbV8xOTJfMi5tM3U4IiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNjg2Mzk4MzYyfX19XX0_&Signature=b7RMCH52IHRRyuwMchgzPNx-D~Bjknj~iG1zhiLMQvGNuWSz6S1xI2kUoUPFTZl7mwFko5ayZ~-RYxhcZ4kwrXNx-HvHoHfLF6zBtqcgzZjSzS5BOD5qLOk0ycTOfgC~RGEpSnY2QLArViNjrvv1NkRKAISXAQa8GOrykWvFmSFoFdTYAyFoQCQsUcafu52navETBUTEIYRlscj3GvyLrQI5M5FoWwoEcziDR7jg56WH2IPFe2hEBOJvPhKLhyVH5HNiw66Y0L2~Gqobb0YPaOOLLcO64ynhOjamaRP7Lu8IYmZGqIL2actCD7WWyuBS-4PmJn7pydnf8aZDtVFKYg__&Key-Pair-Id=APKAICDSGT3Y7IXGJ3TA"
    },
    {
      id: 2,
      music: "KBS 쿨 FM",
      img: "KBScoolFM.png",
      radio:
        //"https://2fm.gscdn.kbs.co.kr/2fm_192_3.m3u8?Expires=1682643564&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly8yZm0uZ3NjZG4ua2JzLmNvLmtyLzJmbV8xOTJfMy5tM3U4IiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNjgyNjQzNTY0fX19XX0_&Signature=E~IsmtEfJKz2kL4rOR-S0ble-A62l98PllpITtzMWvdaCqAja7UHqqNAVDEBEYvB6cnuP25g6hLkMorSyf5VRLiK-XSj2ueDjknqvbCGbWmeviMkEAMlTQYyaBdikppZKxv94gusJFT8QqUEkN8FDHfsZZPxK3N5ICddJRe4tsILaqZEGbgJhmsTFBZmTnqGsyNttYsoKK3jk9gEI8IEVxKI9-nycSCGImnO2sw3PTukwtFKSjKPPXgPO5FcubgTiRcZ1igxSb2EQx2CM4lFNoKq1zAsqZ18YO9jtBNVGvly8d0ODALn6KQ932l5KAbD~tfgjYiHUJOnwWE2sMGAwA__&Key-Pair-Id=APKAICDSGT3Y7IXGJ3TA",
        "https://2fm.gscdn.kbs.co.kr/2fm_192_3.m3u8?Expires=1686398400&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly8yZm0uZ3NjZG4ua2JzLmNvLmtyLzJmbV8xOTJfMy5tM3U4IiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNjg2Mzk4NDAwfX19XX0_&Signature=jvq0vAi99BrqAc4rDQy-vNSJ33TdlG6o-pKcUy~6ij0dGSItTBRGpZ4oknPoB9hu50gq3O4kGcqtDfw0zmH3fDuO235VWUhpqXJ~3HAmW5KtBhQ~iHppQ~HtM1ksIu9t23AOA0OyNu4bwOtmjgWJIOI~Aef7YymjdYGT6nrYlvN30ppCukYpdjBt1MjN7uRgWp6E3-DokKvycKP6SvMD0IE2ExpEW-0V0r-PnQuEKI1Ef38l3IIgXx1veJ~UVoC4IZ4KaQaK2gfFbVT8OcNU-ylduJp88nKue5rzyp83gAic541mrygQdahEqUB2Nbg8lfctpcwksDYz9JNejJzbow__&Key-Pair-Id=APKAICDSGT3Y7IXGJ3TA"
    },
    {
      id: 3,
      music: "KBS 해피 FM",
      img: "KBShappyFM.png",
      radio:
        //"https://2radio-ad.gscdn.kbs.co.kr/2radio_ad_192_1.m3u8?Expires=1682643570&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly8ycmFkaW8tYWQuZ3NjZG4ua2JzLmNvLmtyLzJyYWRpb19hZF8xOTJfMS5tM3U4IiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNjgyNjQzNTcwfX19XX0_&Signature=RG9jEEc0KyPa3eyJYzfmkc6OAlsZZ05~HBdB~HhDBMnFl6ZuvVV36MhWZ5-640DOGY6I~i7TJzgpR5c3r~hGpnuvchLAg~lby1imGmGqjeJ7RX1DmOz2gHS7ebjH4y9q396BaDfb8sRDRXGRlGj-A~KxH1IGbgpVHJ2oWtXLdCLM9ALbpKhpLLDcgbeGknLq-G1bDW30Y~03GekPeI6s7Xc5t~3qlEGnZS1NtG30UCVqoRmNuogMX2SHskt1BoKtSmcPTZsByw07qSkU5R3m4qhFvsFejaopJ3wQjsluAQuXWdbkcilgi4TfQpTwFvGhSS7bi76q9CRKzFRocRy6ww__&Key-Pair-Id=APKAICDSGT3Y7IXGJ3TA",
        "https://2radio-ad.gscdn.kbs.co.kr/2radio_ad_192_1.m3u8?Expires=1686398385&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly8ycmFkaW8tYWQuZ3NjZG4ua2JzLmNvLmtyLzJyYWRpb19hZF8xOTJfMS5tM3U4IiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNjg2Mzk4Mzg1fX19XX0_&Signature=VkvfbXVN91aq9RjIr1Zfig20ttMxsjtRRGqckNu1uRWebezw9Bq1WG09ebQEW~7Q5mLa~msVVgvL~qso06b9Bx~b46RWpQ4ghhUQ3ofnVHkxT3EhmggcDAfrrlrpBlLzfxoFwtgvcZSKLNWSsIhd8ID6KoY~E7zF0yCEBobjIsjVthGyKSqEJIct~3MU27VoYc-xcJbqd1weO~ZLBQDiUJdQU8nqtfoSjf990rsD4bbpdflQDgzwBSklZYMvGfiFHMKPJGeA1Fh-PTMSDV7IEYG~Luk2j77QWnzaLKpJ5VTsC6QTDXNVgi0fkvZ2PD0~za1U1H3UJwZQn~r6KJJWRQ__&Key-Pair-Id=APKAICDSGT3Y7IXGJ3TA"
    },
    {
      id: 4,
      music: "MBC FM4U",
      img: "MBCFM4U.png",
      radio:
        //"https://minimw.imbc.com/dmfm/_definst_/mfm.stream/playlist.m3u8?_lsu_sa_=64D1661FB3AC31F48E4C457C3631A748952F3BC5080FB2CA3070E5a0B69E395624a0734F3C42944F405B3ED186b0B1E256839AC19B27DE24521151544671D024600DB11213D36AC9906E58A793ED0366671687E4B3C25F7B7A77286D72D998EB765D9686C09098FF76D0D8EF6A14CDB0",
        "https://minimw.imbc.com/dmfm/_definst_/mfm.stream/playlist.m3u8?_lsu_sa_=6531FA11D3F63E24BE4205983C61C44E95A939259E0D82C632F0BDa616243BC663a483D737727F4AA009354104b491DE6EDDDCD3A53AF59F8630DD327F06403CF1664B22A52A24AAA0F128508156B75EF73C74CD83961FAEC3100693BCCD6C2E8A7F9949A35224A12C40366678A22528"
    },
    {
      id: 5,
      music: "MBC 표준 FM",
      img: "MBCnormalFM.png",
      radio:
        //"https://minisw.imbc.com/dsfm/_definst_/sfm.stream/playlist.m3u8?_lsu_sa_=6C413314935C3014F540B5983191824105173A35090BC2613AF051aD06023C9622aEE3953182B74530023721D0bEC156A2FB73F4E1E0544BA310E9B62A6F89DE4B2AA0C97BAD7F1A0FCAEA2FE6D823C89AC56B1B1BD37B1538E26327F8EB84C29C6DA144A7CDF5E1BFF6CEB6862E3D1F",
        "https://minisw.imbc.com/dsfm/_definst_/sfm.stream/playlist.m3u8?_lsu_sa_=61D16412F38B3E64B34AE54B3C31EE4545433BE50E0022B93610D5a026913E666Aa8537F34B2DF4DE0D93D6137b0A159B9711A4DD95224951797B4F7F990AE338E241D632C0E0030563943AF0AA592893868606234C8AF3E5D1AA09E80757407E83E021DD98A5B3BC7C69DCADB36EC93"
    },
    {
      id: 6,
      music: "SBS 파워 FM",
      img: "SBSfowerFM.png",
      radio:
        //"https://radiolive.sbs.co.kr/powerpc/powerfm.stream/playlist.m3u8?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODI1MTQwMTAsInBhdGgiOiIvcG93ZXJmbS5zdHJlYW0iLCJkdXJhdGlvbiI6LTEsInVubyI6ImZmOWVhMjBhLWQ1YzAtNGYxMy1hNDQyLWMwYTA2OTVlMGY1NiIsImlhdCI6MTY4MjQ3MDgxMH0.QYhk7T3-bh6ZBpK2i8mWl7lfgVERSxzSpdVKQiBvlf4",
        "https://radiolive.sbs.co.kr/powerpc/powerfm.stream/playlist.m3u8?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODYyNjg4MTAsInBhdGgiOiIvcG93ZXJmbS5zdHJlYW0iLCJkdXJhdGlvbiI6LTEsInVubyI6IjRiNTU1OGJlLTEzM2UtNGY2Yi05MWU2LTZiNjk4M2UzYjk4YyIsImlhdCI6MTY4NjIyNTYxMH0.pQHpCE6BzxNf65N1HPgYJigiD5VKOqQkObXdhiRqQRo"
    },
    {
      id: 7,
      music: "SBS 러브 FM",
      img: "SBSloveFM.png",
      radio:
        //"https://radiolive.sbs.co.kr/lovepc/lovefm.stream/playlist.m3u8?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODI1MTQwMTgsInBhdGgiOiIvbG92ZWZtLnN0cmVhbSIsImR1cmF0aW9uIjotMSwidW5vIjoiNjc1ZmFhMTktOTMxMC00YTJlLTlkZGQtMjg0NDMzNjg0MzYwIiwiaWF0IjoxNjgyNDcwODE4fQ.eauwoqDEI73Gw0sNGtwQT73fb9pKZHN6o0LtHAwPkZM",
        "https://radiolive.sbs.co.kr/lovepc/lovefm.stream/playlist.m3u8?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODYyNjg4MTcsInBhdGgiOiIvbG92ZWZtLnN0cmVhbSIsImR1cmF0aW9uIjotMSwidW5vIjoiMmY3NDQwZDctZWQ4NC00ZGVjLWJlM2ItOGMwNGNlNDY2ODhjIiwiaWF0IjoxNjg2MjI1NjE3fQ.zoExIptnJQgBh6YqzRzPIyEG7dTn69hLCbaOTN3qg18"
    },
    {
      id: 8,
      music: "CBS 표준 FM",
      img: "CBSnormalFM.png",
      radio: "https://aac.cbs.co.kr/cbs981/cbs981.stream/playlist.m3u8",
    },
    {
      id: 9,
      music: "CBS 음악 FM",
      img: "CBSmusicFM.png",
      radio: "https://aac.cbs.co.kr/cbs939/cbs939.stream/playlist.m3u8",
    },
    {
      id: 10,
      music: "EBS FM 104.5",
      img: "EBSFM.png",
      radio:
        "https://ebsonairiosaod.ebs.co.kr/fmradiobandiaod/bandiappaac/playlist.m3u8",
    },
  ],
  reducers: {
    playListChange(state, a) {
      return a.payload;
    },
  },
});
let playNum = createSlice({
  name: "playNum",
  initialState: 0,
  reducers: {
    playNumChange(state, a) {
      return a.payload;
    },
    playNext(state, a) {
      return state + 1;
    },
    playPrev(state, a) {
      return state - 1;
    },
  },
});
let playVolume = createSlice({
  name: "playVolume",
  initialState: 1,
  reducers: {
    playVolumeChange(state, a) {
      return a.payload;
    },
  },
});

export let { playChange } = playing.actions;
export let { playNumChange } = playNum.actions;
export let { playNext } = playNum.actions;
export let { playPrev } = playNum.actions;
export let { playVolumeChange } = playVolume.actions;

export default configureStore({
  reducer: {
    playing: playing.reducer,
    playList: playList.reducer,
    playNum: playNum.reducer,
    playVolume: playVolume.reducer,
  },
});
